html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@font-face {
  font-family: 'test';
  src: url('/test.ttf');
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* 假设设计稿宽度 1920px， 实际开发以设计稿为准 */
/* 小屏自适应, 1rem = 7.3vw  */
html {
  /* (100vw / 1920) * 100 */
  font-size: 5.2vw; 
}

body {
  font-size: 12px;
}

/* 大屏适配, 1rem = 100px */
@media (min-width: 1920px) {
  html {
    font-size: 100px;
  }
}

/* 小屏适配, 1rem = 100px */
@media (max-width: 1200px) {
  html {
    font-size: 60px;
  }
}
